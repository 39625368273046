/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, {useState} from 'react'
import {FC, useEffect, useState} from 'react'
// import {shallowEqual, useSelector} from 'react-redux'
// import {Link} from 'react-router-dom'
// import {UserModel} from '../../../../app/modules/auth/models/UserModel'
// import {RootState} from '../../../../setup'
// import {Languages} from './Languages'
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import {useDispatch} from 'react-redux'
import { logout } from '../../../../app/modules/auth/redux/AuthCRUD'
import { Form } from './Form'
import { Modal, ModalHeader } from 'reactstrap';
import { RegisterCentra, UpdatePasswords } from '../../../../app/modules/store/actions/general/UserAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import package_json from '../../../../../package.json'

const HeaderUserMenu2: FC<{ openModal: any }> = ({openModal}) => {
  const [statusAdmin, setStatusAdmin] = useState(false)
  const role = localStorage.getItem('role') !== null ? JSON.parse(localStorage.role)[0]?.nama : null


  useEffect(() => {
    const aplikasi = JSON.parse(localStorage.aplikasi)
    if (aplikasi.length > 0) {
      const index = aplikasi.findIndex((x:any) => Number(x.app_application_id) === 1);
      setStatusAdmin(index !== -1 && true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [toggleModal, setToggleModal] = useState(false)
  const [modalOpen, setModalOpen] = useState("")
  const [loading, setLoading] = useState(false)
  const [hover, setHover] = useState(false)
  const [selected, setSelected] = useState("")

  const defaultStyle = {
    borderRadius: '0.475rem',
    background: 'white',
    color: "#3f4254",
  };

  const sectionStyle = {
    borderRadius: '0.475rem',
    background: "rgb(250, 250, 250)",
    color: "#009ef7",
  };

  const dispatch = useDispatch()

  function handleLogout() {
    setLoading(true)
    setTimeout(() => {
      logout() 
        .then(response => {
          // localStorage.setItem('token', '')
          // localStorage.setItem('user', '')
          setLoading(false)
          localStorage.clear()
          dispatch(auth.actions.logout())
        })
        .catch(() => {
          setLoading(false)
        })
    }, 1000)

  }

  const handleChangePassword = (data:any) => {
    setLoading(true)
    UpdatePasswords(data)
      .then(() => {
        toast.success("Update Password Sukses !", {position: toast.POSITION.TOP_RIGHT});
        setLoading(false)
        setToggleModal(false)
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Update Data Gagal !", {position: toast.POSITION.TOP_RIGHT});
      })        
  }

  const handleRegisterUserCentra = (data:any) => {
    setLoading(true)
    RegisterCentra(data)
      .then(() => {
        toast.success("Update Password Sukses !", {position: toast.POSITION.TOP_RIGHT});
        setLoading(false)
        setToggleModal(false)
      })
      .catch((err) => {
        setLoading(false)
        toast.error("Update Data Gagal !", {position: toast.POSITION.TOP_RIGHT});
      })        
  }

  return (
    <div className='bg-white fw-bold py-4 fs-6 w-275px mt-4' style={{float: "left", boxShadow: '0 0 50px 0 rgb(82 63 105 / 10%)', borderRadius: '0.475rem'}}>
      <ul style={{listStyleType: 'none', padding: '0', marginBottom: 0}}>
      <div className='menu-item'>
        <div className='menu-content d-flex align-items-center px-6'>
          {/* <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user.pic} />
          </div> */}

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {JSON.parse(localStorage.user).name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {JSON.parse(localStorage.user).email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='px-4'>
        {statusAdmin &&
          <div className='menu-item'>
            {loading ?
              <span className='px-5 menu-link pe-none' style={defaultStyle}>Register User Centra</span> :
              <a onClick={() => {setToggleModal(true);setModalOpen('register')}} className='menu-link px-5' style={(hover && selected === "register") ? sectionStyle : defaultStyle} onMouseEnter={() => {setHover(true); setSelected('register')}} onMouseLeave={() => {setHover(false); setSelected('')}}>
                Register User Centra
              </a>
            }
          </div>
        }
        <div className='menu-item'>
          {loading ?
            <span className='px-5 menu-link pe-none' style={defaultStyle}>Ganti Password</span> :
            <a onClick={() => {setToggleModal(true);setModalOpen('password')}} className='menu-link px-5' style={(hover && selected === "password") ? sectionStyle : defaultStyle} onMouseEnter={() => {setHover(true); setSelected('password')}} onMouseLeave={() => {setHover(false); setSelected('')}}>
              Ganti Password
            </a>
          }
        </div>
        {(role === 'Superadmin' || role === 'Admin Lokal' || role === 'Asset Maintener' || role === 'Asset Owner') &&
          <div className='menu-item'>
            {loading ?
              <span className='px-5 menu-link pe-none' style={defaultStyle}>Scan QR <i className='fa fa-qrcode ms-2'></i></span> :
              <a onClick={() => openModal(true)} className='menu-link px-5' style={(hover && selected === "scan") ? sectionStyle : defaultStyle} onMouseEnter={() => {setHover(true); setSelected('scan')}} onMouseLeave={() => {setHover(false); setSelected('')}}>
                Scan QR <i className='fa fa-qrcode ms-2' style={(hover && selected === "scan") ? sectionStyle : defaultStyle}></i>
              </a>
            }
          </div>
        }
        <div className='menu-item'>
          <a onClick={handleLogout} className='menu-link px-5'
            style={loading ?
              {pointerEvents: 'none', background: 'rgb(250, 250, 250)'} :
              (hover && selected === "logout") ? sectionStyle : defaultStyle}
            onMouseEnter={() => {setHover(true); setSelected('logout')}} onMouseLeave={() => {setHover(false); setSelected('')}}
          >
          Sign Out {loading && <i className="fas fa-spinner fa-pulse"></i>}
          </a>
        </div>
        {/* <div className='menu-item'>
          <a onClick={handleLogout} className='menu-link px-5'
            style={(hover && selected === "logout") ?
              {pointerEvents: 'none', borderRadius: '0.475rem', background: "rgb(250, 250, 250)", color: "#009ef7"} :
              {pointerEvents: 'auto', borderRadius: '0.475rem', background: 'white', color: "#3f4254"}}
            onMouseEnter={() => {setHover(true); setSelected('logout')}} onMouseLeave={() => {setHover(false); setSelected('')}}
          >
            Sign Out {loading && <i className="fas fa-spinner fa-pulse"></i>}
          </a>
        </div> */}
        <div className='menu-item pt-4'>
          <p className='px-5 text-muted mb-0 fs-6'>{"app version: " + package_json.version}</p>
        </div>
      </div>
      </ul>
      {toggleModal &&
        <Modal isOpen={toggleModal} toggle={() => setToggleModal(!toggleModal)} size="lg">
          <ModalHeader toggle={() => setToggleModal(!toggleModal)}>
            {modalOpen === 'password' && "Ganti Password"}
            {modalOpen === 'register' && "Register User Centra"}
          </ModalHeader>
          <Form
            // error={error}
            // errors={errors}
            loading={loading}
            toggleModal={toggleModal}
            modalOpen={modalOpen}
            setToggleModal={setToggleModal}
            handleChangePassword={handleChangePassword}
            handleRegisterUserCentra={handleRegisterUserCentra}
          />
        </Modal>
      }
    </div>
  )
}

export {HeaderUserMenu2}
